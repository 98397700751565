.App {
  text-align: center;
  margin: 0;
  padding: 0;
  padding-bottom: 1rem;
  background-color: #30475e;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23eb5454' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23e58275' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%23d7617f' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%23f28f85' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23ba638e' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%23f07d73' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23aa6fa4' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23ca5953' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23756388' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%23f27b77' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%237c7e9c' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%23e26363' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  height: auto;
  min-height: 100%;
}

.App-header {
  background-color: #7c7e9c;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
